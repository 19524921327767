import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme } from "@mui/material";
import { FC, ReactElement, useEffect, useState } from "react";
import { ReactComponent as TableChartView } from "../icons/table_chart_view.svg";
import { ReactComponent as TableChartViewActive } from "../icons/table_chart_view_fill.svg";
import { ReactComponent as MeetingNotes } from "../icons/sticky_note.svg";
import { ReactComponent as MeetingNotesActive } from "../icons/sticky_note_fill.svg";
import { ReactComponent as MeetingMinutes } from "../icons/auto_stories.svg";
import { ReactComponent as MeetingMinutesActive } from "../icons/auto_stories_fill.svg";
import { ReactComponent as MyTasks } from "../icons/task.svg";
import { ReactComponent as MyTasksActive } from "../icons/task_fill.svg";
import { ReactComponent as ProgressDashboard } from "../icons/space_dashboard.svg";
import { ReactComponent as ProgressDashboardActive } from "../icons/space_dashboard_fill.svg";
import { ReactComponent as MyProjects } from "../icons/folder_open.svg";
import { ReactComponent as MyProjectsActive } from "../icons/folder_open_fill.svg";
import { ReactComponent as ScheduledEvents } from "../icons/schedule.svg";
import { ReactComponent as ScheduledEventsActive } from "../icons/schedule_fill.svg";
import { ReactComponent as AdminPanelSettings } from "../icons/admin_panel_settings.svg";
import { ReactComponent as AdminPanelSettingsActive } from "../icons/admin_panel_settings_fill.svg";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { UserRole } from "../models";
import React from "react";
import { AppContext } from "../models/applicationState";
import { Context } from "../components/context";

const navLinks = [
    {
        index: 1,
        name: 'menu.leadership',
        url: "/leadership",
        icon: 'LeadershipIcon',
        roles: [UserRole.Admin, UserRole.Creator, UserRole.Actor]
    },
    {
        index: 3,
        name: 'menu.create_meeting_note',
        url: "/meetings",
        icon: 'EventNoteIcon',
        roles: [UserRole.Admin, UserRole.Creator]
    },
    {
        index: 4,
        name: 'menu.meeting_minutes_library',
        url: "/library",
        icon: 'LibraryBooksIcon',
        roles: [UserRole.Admin, UserRole.Creator, UserRole.Actor]
    },
    {
        index: 5,
        name: 'menu.actor_dashboard',
        url: "/dashboard/actor",
        icon: 'TaskIcon',
        roles: [UserRole.Admin, UserRole.Creator, UserRole.Actor]
    },
    {
        index: 6,
        name: 'menu.creator_dashboard',
        url: "/dashboard/creator",
        icon: 'DashboardIcon',
        roles: [UserRole.Admin, UserRole.Creator]
    },
    {
        index: 7,
        name: 'menu.manage_projects',
        url: "/projects",
        icon: 'FolderIcon',
        roles: [UserRole.Admin]
    },
    {
        index: 8,
        name: 'menu.manage_roles',
        url: "/users",
        icon: 'AdminPanelSettingsIcon',
        roles: [UserRole.Admin]
    },
    {
        index: 9,
        name: 'menu.my_calendar_events',
        url: "/events",
        icon: 'ScheduleIcon',
        roles: [UserRole.Admin]
    },
];

interface NavProps {
    open: boolean
}
const setActiveNav = (path: string): number => {
    const active = navLinks.filter(({ url }) => path.startsWith(url));
    return active[0]?.index;
}

const MainNav: FC<NavProps> = (props: NavProps): ReactElement => {
    const navigate = useNavigate();
    const theme = useTheme();

    const { t } = useTranslation();
    const location = useLocation();

    const [filteredNavLinks, setFilteredNavLinks] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(setActiveNav(location.pathname) || undefined);

    const appContext = React.useContext<AppContext>(Context);

    useEffect(() => {
        setFilteredNavLinks(navLinks.filter(l => l.roles.includes(appContext.state.invoker?.role)));
    }, [appContext.state.invoker]);

    useEffect(() => {
        setSelectedIndex(setActiveNav(location.pathname));
    }, [navigate]);

    const handleListItemClick = (event: any, selectedIndex: number) => {
        event.preventDefault();
        setSelectedIndex(selectedIndex);
        const selection = navLinks.filter(({ index }) => index === selectedIndex)[0];
        navigate(selection ? selection.url : "/")
    };

    const getNavIcon = (icon: string, active: boolean): ReactElement => {
        switch (icon) {
            case 'LeadershipIcon':
                return active ? <TableChartViewActive color={theme.palette.primary.main} /> : <TableChartView />
            case 'ScheduleIcon':
                return active ? <ScheduledEventsActive color={theme.palette.primary.main} /> : <ScheduledEvents />
            case 'EventNoteIcon':
                return active ? <MeetingNotesActive color={theme.palette.primary.main} /> : <MeetingNotes />
            case 'LibraryBooksIcon':
                return active ? <MeetingMinutesActive color={theme.palette.primary.main} /> : <MeetingMinutes />
            case 'TaskIcon':
                return active ? <MyTasksActive color={theme.palette.primary.main} /> : <MyTasks />
            case 'DashboardIcon':
                return active ? <ProgressDashboardActive color={theme.palette.primary.main} /> : <ProgressDashboard />
            case 'AdminPanelSettingsIcon':
                return active ? <AdminPanelSettingsActive color={theme.palette.primary.main} /> : <AdminPanelSettings />
            case 'FolderIcon':
                return active ? <MyProjectsActive color={theme.palette.primary.main} /> : <MyProjects />
            default:
                return <div />;
        }
    }

    return (
        <List component="nav">
            {filteredNavLinks.map(({ index, name, icon }) => (
                <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: props.open ? 'initial' : 'center',
                            px: 2.5,
                            ":hover": {
                                backgroundColor: 'action.hover'
                            },
                            "&.Mui-selected": {
                                backgroundColor: 'action.selected',
                                '&:hover': {
                                    backgroundColor: 'action.focus'
                                }
                            }
                        }}
                        selected={selectedIndex === index}
                        onClick={(event) => handleListItemClick(event, index)}
                    > 
                        <ListItemIcon
                            children={getNavIcon(icon, selectedIndex === index)}
                            sx={{
                                minWidth: 0,
                                mr: props.open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        />
                        <ListItemText primary={t(name)} sx={{ opacity: props.open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>))
            }
        </List>
        
    );
}
export default MainNav;