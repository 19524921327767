import { GridActionsCellItem, GridColDef, GridRowId } from '@mui/x-data-grid';
import { withApplicationInsights } from "../../components/telemetry";
import { useContext, useEffect, useMemo, useState } from 'react';
import { bindActionCreators } from '../../actions/actionCreators';
import { AppContext } from '../../models/applicationState';
import { Context } from '../../components/context';
import * as eventActions from '../../actions/eventActions';
import CheckIcon from '@mui/icons-material/Check';
import { EventActions } from '../../actions/eventActions';
import { CalendarEvent } from '../../models/calendarEvent';
import { useNavigate } from 'react-router-dom';
import dateFormatter from '../../util/dateFormatter';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { useTranslation } from 'react-i18next';
import { TooltipIcon } from '../../components/tooltipIcon';
import { StyledDataGrid } from '../../components/styledGrid';

const Events = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const appContext = useContext<AppContext>(Context);
    const actions = useMemo(() => ({
        events: bindActionCreators(eventActions, appContext.dispatch) as unknown as EventActions,
    }), [appContext.dispatch]);

    const [events, setEvents] = useState<CalendarEvent[]>([]);

    async function fetchData() {
        // TODO Mircea: handle timezone?
        try {
            const response = await eventActions.list();
            setEvents(response.map(e => ({
                id: e.id,
                name: e.subject,
                startDate: new Date(e.start.dateTime),
                creator: e.organizer.emailAddress.address,
                invitees: e.attendees.map(a => a.emailAddress.address)
            })));
        } catch (e) {
            console.info(`Could not retrieve events - ${e}`)
        }
    }

    // Handle items changed
    useEffect(() => {
        fetchData();
    }, [appContext.state.selectedEvent]);

    const handleSelect = (id: GridRowId) => () => {
        onEventSelected(events.find(e => e.id == id.toString()));
        navigateToMeetings();
    };

    const navigateToMeetings = () => {
        navigate('../meetings', { replace: true });
        // TODO Diana Select Create Meeting Item nav
    };

    const onEventSelected = async (event: CalendarEvent) => {
        actions.events.select(event);
    }

    const columns: GridColDef[] = [
        { field: 'name', headerName: t('events.name'), editable: false, width: 300 },
        {
            field: 'startDate',
            headerName: t('events.startDate'),
            editable: false,
            width: 120,
            valueFormatter: value =>
                value ? dateFormatter.format(new Date(value)) : "-"
        },
        { field: 'creator', headerName: t('events.creator'), editable: false, width: 500 },
        { field: 'invitees', headerName: t('events.invitees'), editable: false, width: 500 },
        {
            field: 'actions',
            type: 'actions',
            headerName: t('events.actions'),
            cellClassName: 'actions',
            getActions: ({ id }) => {

                return [
                    <GridActionsCellItem
                        icon={<TooltipIcon titleKey={'events.actions'} icon={<CheckIcon />} />}
                        label="Select"
                        sx={{
                            color: 'primary.main',
                        }}
                        onClick={handleSelect(id)}
                    />,
                ];

            },
        },
    ];

    return (
        <Box sx={{backgroundColor: "background.default"}}>
            <CssBaseline />
            <div style={{ height: '90vh', width: '100%' }}>
                <StyledDataGrid
                    rows={events}
                    columns={columns}
                />
            </div>

        </Box>
    );
};

export default withApplicationInsights(Events, "Events");
