import {
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { Meeting, MeetingStatus } from "../../models";
import * as listActions from "../../actions/listActions";
import { Context } from "../../components/context";
import { AppContext } from "../../models/applicationState";
import { ListActions } from "../../actions/listActions";
import { bindActionCreators } from "../../actions/actionCreators";
import { withApplicationInsights } from "../../components/telemetry";
import { creatorColumn, dateColumn, emptyMeetingRepresentation, libraryIsPublishedColumn, nameColumn, numberOfInviteesColumn, numberOfViewersColumn } from "../../models/meetingRepresentation";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { GridActionsCellItem, GridColDef, GridRowId } from "@mui/x-data-grid";

import { AttachmentsPane } from "../../components/attachmentsPane";
import { EventActions } from "../../actions/eventActions";
import * as eventActions from '../../actions/eventActions';
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Tooltip, Typography, useTheme } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import EditableGrid from "../../components/editableGrid";
import { ReactComponent as PublishedIcon } from "../../icons/mark_email_read_fill.svg";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import { TooltipIcon } from "../../components/tooltipIcon";
import PersonIcon from '@mui/icons-material/Person';
import CustomToolbar from "../../components/customToolbar";
import * as customListActions from '../../actions/listActions';
import ConfirmationPanel from "../../components/confirmationPanel";

const MeetingsLibraryPage = () => {
    const MEETING = "meeting";
    const navigate = useNavigate();
    const { t } = useTranslation();
    const appContext = useContext<AppContext>(Context);
    const [showAttachmentsForMeeting, setShowAttachmentsForMeeting] = useState(undefined);
    const [showMeetingNavigation, setShowMeetingNavigation] = useState(undefined);
    const { instance } = useMsal();
    const [emptyMeeting, setEmptyMeeting] = useState(emptyMeetingRepresentation);
    const [selectedProjectFilters, setSelectedProjectFilters] = useState(appContext.state.selectedProjectFilters);
    const [meetings, setMeetings] = useState(appContext.state.lists || []);
    const [gridItems, setGridItems] = useState(meetings);
    const theme = useTheme();

    const actions = useMemo(
        () => ({
            lists: bindActionCreators(
                listActions,
                appContext.dispatch
            ) as unknown as ListActions,
            events: bindActionCreators(eventActions, appContext.dispatch) as unknown as EventActions,
        }),
        [appContext.dispatch]
    );

    useEffect(() => {
        if (selectedProjectFilters !== appContext.state.selectedProjectFilters) {
            setSelectedProjectFilters(appContext.state.selectedProjectFilters);
        }
    }, [appContext.state.selectedProjectFilters, selectedProjectFilters, navigate]);

    function matchesProjectFilters(meeting: Meeting): boolean {
        if (selectedProjectFilters.tagIds?.length) {
            return (selectedProjectFilters?.tagIds.some(tagId => meeting.defaultTags.includes(tagId)));
        }
        return selectedProjectFilters?.projectIds.includes(meeting.project);
    }

    useEffect(() => {
        if (selectedProjectFilters?.projectIds?.length || selectedProjectFilters?.tagIds?.length) {
            setGridItems(sortMeetings(meetings.filter(m => matchesProjectFilters(m))));
        } else {
            setGridItems(sortMeetings(meetings));
        }
    }, [meetings, selectedProjectFilters, navigate]);

    const sortMeetings = (meetings: Meeting[]) => {
        return [...(meetings || [])]?.sort((a, b) => {
            if (a.createdDate < b.createdDate) return 1;
            if (a.createdDate > b.createdDate) return -1;
            return 0;
        });
    }

    useEffect(() => {
        setMeetings(appContext.state.lists);
    }, [appContext.state.lists, navigate]);

    const onArchived = (archived: boolean) => {
        onArchivedAsync(archived);
    }
    const onArchivedAsync = async (archived: boolean) => {
        if (archived) {
            const meetings = await customListActions.getArchived();
            setMeetings(sortMeetings(meetings));
        } else {
            setMeetings(sortMeetings(appContext.state.lists));
        }
    };

    const [archiveItem, setArchiveItem] = useState<Meeting>(null);
    const [archiveConfirmationModal, setArchiveConfirmationModal] = useState(false);

    // Handle the displaying of the modal based on id
    const showArchiveModal = (id: GridRowId) => () => {
        const item = meetings.find(r => r.id === id.toString());
        setArchiveItem(item);
        setArchiveConfirmationModal(true);
    };
    // Hide the modal
    const hideConfirmationModal = () => {
        setArchiveConfirmationModal(false);
    };

    // Handle the actual deletion of the item
    const submitArchive = () => {
        onArchive(archiveItem);
        setArchiveConfirmationModal(false);
    };

    useEffect(() => {
        if (emptyMeeting && instance.getActiveAccount()?.username) {
            const participants = emptyMeeting.participants || [];
            if (!participants.includes(instance.getActiveAccount()?.username)) {
                participants.push(instance.getActiveAccount()?.username);
                setEmptyMeeting({ ...emptyMeeting, participants });
            }
        }
    }, [instance.getActiveAccount()]);

    const onMeetingSelected = async (meeting: Meeting) => {
        actions.lists.select(meeting);
    }

    const onEdit = (meeting: Meeting) => {
        if (meeting) {
            onMeetingSelected(meeting);
            navigate(`/meetings/${meeting.id}`);
        }
    };

    const onNavigate = (meeting: Meeting) => {
        if (meeting) {
            onMeetingSelected(meeting);
            if (meeting.status === MeetingStatus.Published) {
                navigate(`/library/${meeting.id}`);
            } else {
                console.info(`show navigation msg for ${meeting.id}`);
                setShowMeetingNavigation(meeting.id);
            }
        }
    };

    const onArchive = (meeting: Meeting) => {
        if (meeting) {
            actions.lists.save({ ...meeting, archived: true });
        }
    };

    const onDelete = (meeting: Meeting) => {
        if (meeting) {
            actions.lists.remove(meeting.id);
        }
    };

    const onMeetingUpserted = async (_newMeeting: Meeting, _oldMeeting?: Meeting) => {
        return;
    }

    const openAttachments = (id: GridRowId) => () => {
        setShowAttachmentsForMeeting(id);
    };

    const gridActions = (id: GridRowId, row: Meeting) => {
        const handleNavigate = (_id: GridRowId) => () => {
            onNavigate(row);
        };

        const handleEdit = (_id: GridRowId) => () => {
            onEdit(row);
        };

        return [
            <GridActionsCellItem
                icon={<TooltipIcon titleKey={'general.open'} icon={<ChevronRightIcon />} />}
                label="Open"
                onClick={handleNavigate(id)}
                sx={{
                    color: 'primary.main',
                }}
            />,
            <GridActionsCellItem
                icon={<TooltipIcon titleKey={'general.edit'} icon={<EditOutlinedIcon />} />}
                label="Edit"
                onClick={handleEdit(id)}
                disabled={row.creator !== instance.getActiveAccount()?.username}
                sx={{
                    color: 'primary.main',
                }}
            />,
            <GridActionsCellItem
                icon={<TooltipIcon titleKey={'general.archive'} icon={<ArchiveOutlinedIcon />} />}
                label="Archive"
                onClick={showArchiveModal(id)}
                disabled={row.archived}
                sx={{
                    color: 'primary.main',
                }}
            />,
        ];
    }

    // const attachmentsColumn: GridColDef<any> = {
    //     field: 'attachments',
    //     headerName: 'dashboard.meetingAttachments',
    //     type: 'actions',
    //     flex: 0.4,
    //     cellClassName: 'actions',
    //     getActions: ({ id, row }) => {
    //         return [
    //             <GridActionsCellItem
    //                 icon={
    //                     <TooltipIconWithBadge
    //                         badgeContent={row.attachments}
    //                         titleKey='dashboard.meetingAttachments'
    //                         icon={< FileCopyIcon fontSize="small" />}
    //                     />
    //                 }
    //                 label={t('meeting.attachments')}
    //                 onClick={openAttachments(id)}
    //                 sx={{
    //                     color: 'primary.main',
    //                 }}
    //                 disabled={id == emptyMeetingRepresentation.id}
    //             />,
    //         ];
    //     },
    // }

    return (
        <Box
            sx={{
                '& .scroll-overflow': {
                    overflow: 'auto !important'
                }
            }}>
            <CssBaseline />
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', marginTop: 1, marginBottom: 1 }}>
                {/* <Typography variant="body1" sx={{ marginLeft: 1, fontStyle: "italic" }}>
                    {t('library.overview')}
                </Typography> */}
            </Box>
            {showMeetingNavigation && (
                <>
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
                        <div className="bg-white p-8 rounded-lg w-96">
                            <p className="text-base leading-normal mb-4">{t('library.not_published_navigation')}</p>
                            <div className="flex justify-center space-x-4 pt-4">

                                <Button variant="outlined" onClick={() => setShowMeetingNavigation(undefined)}>
                                    <Typography variant="button" display="block">
                                        {t('button.cancel')}
                                    </Typography>
                                </Button>
                                <Button variant="contained" onClick={() => {
                                    setShowMeetingNavigation(undefined);
                                    navigate(`/meetings/${showMeetingNavigation}`);
                                }}>
                                    <Typography variant="button" display="block">
                                        {t('button.continue')}
                                    </Typography>
                                </Button>

                            </div>
                        </div>
                    </div>
                </>
            )}
            {showAttachmentsForMeeting
                && (
                    <AttachmentsPane
                        allowModifications={false}
                        height='70vh'
                        uploadPrefix={`meetings/${showAttachmentsForMeeting}/`}
                        meetingId={showAttachmentsForMeeting}
                        onClose={() => setShowAttachmentsForMeeting(undefined)}
                        onAttachmentsCountChanged={(count, meetingId, _itemId) => setMeetings(meetings.map(m => m.id === meetingId ? { ...m, attachments: count } : m))}
                    />
                )}
            <EditableGrid
                processRowUpdate={onMeetingUpserted}
                rows={gridItems}
                actionsHeaderKey=''
                onDelete={onDelete}
                columns={[
                    { ...dateColumn, editable: false },
                    { ...nameColumn, editable: false, flex: 0.6 },
                    {
                        ...creatorColumn,
                        width: 240,
                        renderCell: (params) => (
                            <Tooltip title={params.row.creator}>
                                <Box sx={{
                                    textOverflow: "ellipsis",
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden'
                                }}>
                                    {params.row.creator === instance.getActiveAccount()?.username && (
                                        <Checkbox
                                            disabled
                                            checkedIcon={<PersonIcon />}
                                            checked={true} />
                                    )}
                                    <span>{params.row.creator}</span>
                                </Box>
                            </Tooltip>
                        ),
                    },
                    numberOfInviteesColumn,
                    numberOfViewersColumn,
                    {
                        ...libraryIsPublishedColumn as GridColDef,
                        renderCell: (params) => (
                            <Box>
                                {params.row.status === MeetingStatus.Published
                                    && (
                                        <TooltipIcon titleKey={'library.published.status'} 
                                        icon={<PublishedIcon color={theme.palette.primary.main} />} />
                                    )}
                            </Box>
                        ),
                    }]
                }
                height='85vh'
                extraActions={gridActions}
                modelType={MEETING}
                toolbar={CustomToolbar}
                toolbarProps={
                    {
                        toolbar: {
                            showSettings: false,
                            hideItemStatusToggles: true,
                            onArchived: onArchived,
                        }
                    }
                }
            />
            {archiveConfirmationModal &&
                <ConfirmationPanel
                    showModal={archiveConfirmationModal}
                    confirmModal={submitArchive}
                    hideModal={hideConfirmationModal}
                    entry={archiveItem}
                    type={MEETING}
                    action="archive"
                />}
        </Box>
    );
};

export default withApplicationInsights(MeetingsLibraryPage, "MeetingsLibraryPage");
