import {
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { MeetingItem, MeetingItemType, MeetingStatus } from "../../models";
import * as itemActions from "../../actions/itemActions";
import * as listActions from "../../actions/listActions";
import { Context } from "../../components/context";
import { AppContext } from "../../models/applicationState";
import { ItemActions } from "../../actions/itemActions";
import { ListActions } from "../../actions/listActions";
import { useNavigate, useParams } from "react-router-dom";
import { bindActionCreators } from "../../actions/actionCreators";
import { withApplicationInsights } from "../../components/telemetry";
import { MeetingHeader } from "../../components/meetingHeader";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import CrudGrid from "../../components/crudGrid";
import { GridActionsCellItem, GridColDef, GridRenderCellParams, GridRowId, GridSortDirection, GridSortModel, GridTreeNodeWithRender } from "@mui/x-data-grid";
import { MeetingItemRepresentation, actorColumn, addIcon, categoryColumn, contentColumn, convertToRepresentationSortedByCreateDate, decisionColumn, dueDateColumn, emptyItemRepresentation, getMeetingItemFromRepresentation, hasRepresentationChanged, informationColumn, isStatusUpdated, tagsColumn, taskColumn } from "../../models/meetingItemRepresentation";
import { Checkbox, Tooltip } from "@mui/material";
import { AttachmentsPane } from "../../components/attachmentsPane";
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TaskIcon from '@mui/icons-material/Task';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import SignpostIcon from '@mui/icons-material/Signpost';
import SignpostOutlinedIcon from '@mui/icons-material/SignpostOutlined';
import { FreeSingleSelect } from "../../components/freeSingleSelect";
import { useTranslation } from "react-i18next";
import AddIcon from '@mui/icons-material/Add';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TooltipIcon } from "../../components/tooltipIcon";
import { TooltipIconWithBadge } from "../../components/tooltipIconWithBadge";
import { FreeCheckboxMultipleSelect } from "../../components/freeCheckboxMultipleSelect";
import { Tag } from "../../models/tag";
import { Project } from "../../models/project";
import { ItemEditingPanel, addStatusUpdateSystemCommentToItem, hasUserComments } from "../../components/itemEditingPanel";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useMsal } from "@azure/msal-react";

const ItemsPage = () => {
    const ITEM = "item";
    const ITEM_SORT_FIELD = "item.sortField";
    const ITEM_SORT_DIRECTION = "item.sortDirection";
    const navigate = useNavigate();
    const { t } = useTranslation();
    const appContext = useContext<AppContext>(Context);
    const { meetingId } = useParams();
    const actions = useMemo(
        () => ({
            lists: bindActionCreators(
                listActions,
                appContext.dispatch
            ) as unknown as ListActions,
            items: bindActionCreators(
                itemActions,
                appContext.dispatch
            ) as unknown as ItemActions,
        }),
        [appContext.dispatch]
    );

    const [showTasksWarning, setShowTasksWarning] = useState(false);
    const [showAttachmentsPane, setShowAttachmentsPane] = useState(false);
    const [items, setItems] = useState<MeetingItemRepresentation[]>([]);
    const [showAttachmentsForItem, setShowAttachmentsForItem] = useState(undefined);
    const [showEditingPanelForItem, setShowEditingPanelForItem] = useState(undefined);
    const [projects, setProjects] = useState(appContext.state.invoker?.projects || []);
    const [meetingName, setMeetingName] = useState(appContext.state.selectedList?.name);
    const { instance } = useMsal();
    // React to selected list changes
    useEffect(() => {
        if (meetingId && appContext.state.selectedList?.id !== meetingId) {
            actions.lists.load(meetingId);
        }
    }, [actions.lists, appContext.state.selectedList, meetingId, navigate])

    useEffect(() => {
        if (appContext.state.invoker?.projects?.length) {
            setProjects(appContext.state.invoker?.projects);
        }
    }, [appContext.state.invoker?.projects]);

    useEffect(() => {
        const project = (projects?.length && appContext.state.selectedList?.project) ? projects.find(p => p.id === appContext.state.selectedList?.project) : undefined;
        setMeetingName(appContext.state.selectedList?.name + (project ? ` (${project.name})` : ''));
    }, [projects, appContext.state.selectedList?.project]);

    const filterOnlyLiveItems = (items: MeetingItem[]) => {
        return items?.filter(i => !i.isSnapshot) || [];
    }

    // Load items for selected list
    useEffect(() => {
        if (appContext.state.selectedList?.id && !appContext.state.selectedList.items) {
            const loadListItems = async (listId: string) => {
                await actions.items.list(listId);
            }

            loadListItems(appContext.state.selectedList.id)
        }
        if (appContext.state.selectedList?.items) {
            setItems(convertToRepresentationSortedByCreateDate(filterOnlyLiveItems(appContext.state.selectedList.items)));
        }
    }, [actions.items, appContext.state.selectedList?.id, appContext.state.selectedList?.items])

    const onItemUpserted = async (newItem: MeetingItemRepresentation, oldItem?: MeetingItemRepresentation) => {
        // This check avoids item creation from the top row of the crud grid on edit
        if (newItem.id === emptyItemRepresentation.id) {
            return;
        }
        newItem.project = appContext.state.selectedList.project;
        newItem.tags = newItem.tags || appContext.state.selectedList.defaultTags;
        const statusUpdated = isStatusUpdated(newItem);
        if (statusUpdated) {
            const userFullName = (appContext.state.invoker.lastName && appContext.state.invoker.firstName) ? (appContext.state.invoker?.firstName + ' ' + appContext.state.invoker?.lastName) : instance.getActiveAccount()?.name;
            addStatusUpdateSystemCommentToItem(newItem, userFullName, t)
        }
        const item = getMeetingItemFromRepresentation(newItem);
        if (!item.types.includes(MeetingItemType.Task)) {
            item.actor = null;
        }
        const meeting = appContext.state.selectedList;
        if (item.actor) {
            if (!meeting.participants?.includes(item.actor) && !meeting.viewers?.includes(item.actor)) {
                // We have assigned a task to an email not in participants. Add that email as viewer on the meeting.
                if (!meeting.viewers) {
                    meeting.viewers = [item.actor];
                } else {
                    meeting.viewers.push(item.actor);
                }
                await actions.lists.save(meeting);
                await actions.lists.list();
            }
        }
        if (hasRepresentationChanged(newItem)) {            
            return await actions.items.save(meeting.id, item);
        }
    }

    const setMeetingInReview = () => {
        const meeting = appContext.state.selectedList;
        if (meeting.status !== MeetingStatus.InReview && meeting.status !== MeetingStatus.Published) {
            meeting.status = MeetingStatus.InReview;
            actions.lists.save(meeting);
        }
    }

    const onRowChanged = async (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>, row: MeetingItemRepresentation) => {
        onItemUpserted(params.row);
        params.api.updateRows([{ ...params.row }]);
    }

    const onItemDeleted = (itemRepresentation: MeetingItemRepresentation) => {
        const item = getMeetingItemFromRepresentation(itemRepresentation);
        if (item.id) {
            actions.items.remove(appContext.state.selectedList.id, item);
        }
    }

    const onItemUpdated = async (itemRepresentation: MeetingItemRepresentation) => {
        const meeting = appContext.state.selectedList;
        const statusUpdated = isStatusUpdated(itemRepresentation);
        if (statusUpdated) {
            const userFullName = (appContext.state.invoker.lastName && appContext.state.invoker.firstName) ? (appContext.state.invoker?.firstName + ' ' + appContext.state.invoker?.lastName) : instance.getActiveAccount()?.name;
            addStatusUpdateSystemCommentToItem(itemRepresentation, userFullName, t)
        }
        const item = getMeetingItemFromRepresentation(itemRepresentation);
        
        // if (hasRepresentationChanged(itemRepresentation)) {
        //save item without checking if it changed
        await actions.items.save(meeting.id, item);
        // }
    }

    const checkTasksForReview = (): MeetingItem[] => {
        let reviewTasks = appContext.state.selectedList.items.filter(item => item.types.includes("task"));
        reviewTasks = reviewTasks.filter(t => !t.dueDate || !t.actor);
        return reviewTasks;
    }

    const prepareForReview = () => {
        if (appContext.state.selectedList?.status !== MeetingStatus.Published && checkTasksForReview().length > 0) {
            setShowTasksWarning(true);
        } else {
            setMeetingInReview();
            navigate(`/meetings/${appContext.state.selectedList?.id}/review`);
        }
    }

    const showReviewMessage = () => {
        const noOfTasks = checkTasksForReview().length;
        return t('review_warning.task', { count: noOfTasks });
    }

    const openItemAttachments = (id: GridRowId, meetingId: string) => () => {
        setShowAttachmentsForItem({ id, meetingId });
    };
    const openItemEditingPanel = (item: MeetingItemRepresentation) => () => {
        setShowEditingPanelForItem({ item });
    };


    const itemNavigateActions = (id: GridRowId, row: MeetingItemRepresentation) => {
        return ([
            <GridActionsCellItem
                icon={<TooltipIconWithBadge titleKey={'general.open'} icon={<ChevronRightIcon/>} badgeContent={hasUserComments(row) ? "i" : undefined} />}
                label="Edit"
                onClick={openItemEditingPanel(row)}
                disabled={!row.data}
                sx={{
                    color: 'primary.main',
                }}
            />,
            <GridActionsCellItem
                icon={
                    <TooltipIconWithBadge
                        badgeContent={row.attachments}
                        titleKey='dashboard.itemAttachments'
                        icon={<AttachFileIcon/>}
                    />
                }
                label={t('dashboard.itemAttachments_label')}
                onClick={openItemAttachments(id, row.meetingId)}
                sx={{
                    color: 'primary.main',
                }}
            />]);
    }

    const formatInputs = (values: (Tag | string)[]): string[] => {
        return values.map(v => {
            if (typeof v === 'string') {
                return v;
            }
            return v.id;
        });
    };

    const getTagOptions = (project: Project) => {
        return project?.tags || [];
    }

    const getFreeMultipleSelectComponentForTags = (saveUpsert: boolean) => {
        return {
            ...tagsColumn,
            editable: false,
            renderCell: (params) => (
                <FreeCheckboxMultipleSelect
                    onValueSelected={(value) => {
                        params.row.tags = value;
                        if (saveUpsert) {
                            onItemUpserted(params.row);
                        }
                        params.api.updateRows([{ id: params.row.id, data: params.row }]);
                    }}
                    options={getTagOptions(projects.find(p => p.id === appContext.state.selectedList?.project))}
                    values={(params.row.tags || appContext.state.selectedList?.defaultTags || []).filter(t => projects.find(p => p.id === appContext.state.selectedList.project)?.tags?.map(t => t.id).includes(t))}
                    placeholder={t('item.tags')}
                    formatValues={(values) => {
                        const valueIds = values.map(v => v.id ? v.id : v);

                        return projects.find(p => p.id === appContext.state.selectedList?.project)?.tags.filter(
                            t => valueIds.includes(t.id)
                        ).map(t => t.name)
                    }}
                    formatInputs={formatInputs}
                    isOptionEqualToValue={(option, value) => option.id === value}
                    disableFreeSolo={true}
                />
            )
        };
    }

    const columns: GridColDef[] = [
        { ...categoryColumn, editable: true },
        { ...contentColumn, editable: true, flex: 0.6 },
        {
            ...taskColumn as GridColDef,
            renderCell: (params) => (
                <Tooltip title={t('item.taskType')}>
                    <Checkbox
                        icon={<TaskOutlinedIcon />}
                        checkedIcon={<TooltipIcon titleKey={'general.open'} icon={<TaskIcon />} />}
                        sx={{ color: 'secondary.light' }}
                        checked={params.row.task}
                        onChange={() => {
                            params.row.task = !params.row.task;
                            if (!params.row.task) {
                                params.row.actor = undefined;
                            }
                            onRowChanged(params, params.row);
                        }}
                    />
                </Tooltip>
            )
        },
        {
            ...decisionColumn as GridColDef,
            renderCell: (params) => (
                <Tooltip title={t('item.decisionType')}>
                    <Checkbox
                        icon={<SignpostOutlinedIcon />}
                        checkedIcon={<SignpostIcon />}
                        sx={{ color: 'secondary.light' }}
                        checked={params.row.decision}
                        onChange={() => {
                            params.row.decision = !params.row.decision;
                            onRowChanged(params, params.row);
                        }}
                    />
                </Tooltip>
            )
        },
        {
            ...informationColumn as GridColDef,
            renderCell: (params) => (
                <Tooltip title={t('item.informationType')}>
                    <Checkbox
                        icon={<InfoOutlinedIcon />}
                        checkedIcon={<TooltipIcon titleKey={'general.open'} icon={<InfoIcon />} />}
                        sx={{ color: 'secondary.light' }}
                        checked={params.row.info}
                        onChange={() => {
                            params.row.info = !params.row.info;
                            onRowChanged(params, params.row);
                        }}
                    />
                </Tooltip>
            )
        },
        {
            ...actorColumn as GridColDef,
            width: 280,
            renderCell: (params) => (
                <FreeSingleSelect
                    onValueSelected={(value) => {
                        params.row.actor = value;
                        onRowChanged(params, params.row);
                    }}
                    options={appContext.state.selectedList?.participants}
                    value={params.row.actor}
                    placeholder={params.row.actor}
                    disabled={!params.row.task}
                />
            )
        },
        dueDateColumn
    ];

    const topColumns: GridColDef[] = [...columns];
    if (projects?.length) {
        topColumns.push(getFreeMultipleSelectComponentForTags(false));
    }
    const bottomColumns: GridColDef[] = [...columns];
    if (projects?.length) {
        bottomColumns.push(getFreeMultipleSelectComponentForTags(true));
    }

    async function updateSortModel(newSortModel: GridSortModel) {
        localStorage.setItem(ITEM_SORT_FIELD, newSortModel[0]?.field);
        localStorage.setItem(ITEM_SORT_DIRECTION, newSortModel[0]?.sort);
    }

    return (
        <Box>
            <CssBaseline />
            <MeetingHeader meetingName={meetingName}
                primaryBtnText={t('button.review')}
                onPrimaryBtnClick={() => prepareForReview()}
                secondaryBtnText={t('button.attachments', { count: appContext.state.selectedList?.attachments || 0 })}
                onSecondaryBtnClick={() => setShowAttachmentsPane(true)}
            />
            {showTasksWarning ? (
                <>
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
                        <div className="bg-white p-8 rounded-lg w-96">
                            <p className="text-base leading-normal mb-4">{showReviewMessage()}</p>
                            <div className="flex justify-center space-x-4 pt-4">

                                <Button variant="outlined" onClick={() => setShowTasksWarning(false)}>
                                    <Typography variant="button" display="block">
                                        {t('button.cancel')}
                                    </Typography>
                                </Button>
                                <Button variant="contained" onClick={() => {
                                    setShowTasksWarning(false);
                                    setMeetingInReview();
                                    navigate(`/meetings/${appContext.state.selectedList?.id}/review`);
                                }}>
                                    <Typography variant="button" display="block">
                                        {t('button.continue')}
                                    </Typography>
                                </Button>

                            </div>
                        </div>
                    </div>
                </>
            ) : null}

            {showAttachmentsPane && (
                <AttachmentsPane
                    allowModifications={true}
                    height='70vh'
                    uploadPrefix={`meetings/${appContext.state.selectedList?.id}/`}
                    meetingId={appContext.state.selectedList?.id}
                    onClose={() => setShowAttachmentsPane(false)}
                    onAttachmentsCountChanged={(count, _meetingId, _itemId) => { appContext.state.selectedList.attachments = count }}
                />
            )}

            {showAttachmentsForItem
                && (
                    <AttachmentsPane
                        allowModifications={true}
                        height='70vh'
                        uploadPrefix={`items/meetings/${showAttachmentsForItem.meetingId}/items/${showAttachmentsForItem.id}/`}
                        meetingId={showAttachmentsForItem.meetingId}
                        itemId={showAttachmentsForItem.id}
                        onClose={() => setShowAttachmentsForItem(undefined)}
                        onAttachmentsCountChanged={(count, _meetingId, itemId) => setItems(items.map(item => item.id === itemId ? { ...item, attachments: count } : item))}
                    />
                )}

            {showEditingPanelForItem
                && (
                    <ItemEditingPanel allowMetadataEditing meetingItem={showEditingPanelForItem.item} open={showEditingPanelForItem} onClose={() => setShowEditingPanelForItem(undefined)} onUpdate={onItemUpdated}
                    />
                )}

            <Container maxWidth={false} disableGutters>
                <CrudGrid
                    processRowUpdate={onItemUpserted}
                    onDelete={onItemDeleted}
                    rows={items}
                    columns={bottomColumns}
                    height='80vh'
                    emptyRow={emptyItemRepresentation}
                    modelType={ITEM}
                    topColumns={topColumns}
                    mandatoryColumnNames={[contentColumn.field]}
                    extraActions={itemNavigateActions}
                    actionsHeaderKey=''
                    isCellEditable={(params) => params.row.task || params.field !== 'actor'}
                    sortField={localStorage.getItem(ITEM_SORT_FIELD)}
                    sortDirection={localStorage.getItem(ITEM_SORT_DIRECTION) as GridSortDirection}
                    updateSortModel={updateSortModel} />
            </Container>
        </Box>
    );
};

export default withApplicationInsights(ItemsPage, "ItemsPage");
