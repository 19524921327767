import { GridColDef } from "@mui/x-data-grid";
import dateFormatter from "../util/dateFormatter";
import { MeetingStatus } from "./meeting";

export const emptyMeetingRepresentation = {
    id: "3333",
    name: "",
    date: dateFormatter.format(new Date()),
    status: MeetingStatus.Draft,
    participants: [],
    eventId: undefined
}

export const meetingStatusLabels = {
    "draft": "meeting_status.draft",
    "inreview": "meeting_status.inreview",
    "reviewed": "meeting_status.reviewed",
    "published": "meeting_status.published"
}

//columns for the top row - adding a new meeting 
export const addIcon: GridColDef = { field: 'addIcon', editable: false, width: 60, display: 'flex' };

//columns for the meetings table
export const identifierColumn: GridColDef = { field: 'identifier', headerName: 'meeting.identifier', width: 60, editable: false, hideable: false, display: 'flex' };
export const nameColumn: GridColDef = { field: 'name', headerName: 'meeting.name', editable: true, width: 200 };
export const creatorColumn = { field: 'creator', headerName: 'meeting.creator', editable: false };
export const dateColumn: GridColDef = {
    field: 'date',
    headerName: 'meeting.date',
    type: 'date',
    width: 120,
    editable: true,
    valueSetter: (value, row) => {
        if (value) {
            const localDate = new Date(value);
            localDate.setHours(12, 0, 0, 0);
            const utcTime = localDate.getTime() - localDate.getTimezoneOffset() * 60000;
            row.date = new Date(utcTime).toString();
            return row;
        }
        return row;
    },
    valueFormatter: (value) =>
        value ? dateFormatter.format(new Date(value)) : "-"
}

export const numberOfInviteesColumn: GridColDef = {
    field: 'participants',
    headerName: 'meeting.participants',
    editable: false,
    flex: 0.1,
    // valueFormatter: params => params?.value?.length ?? 0,
    valueGetter: (_value, row) => row.participants?.length ?? 0
};

export const numberOfViewersColumn: GridColDef = {
    field: 'viewers',
    headerName: 'library.viewers',
    editable: false,
    flex: 0.1,
    // valueFormatter: params => params?.value?.length ?? 0,
    valueGetter: (_value, row) => row.viewers?.length ?? 0
};

export const inviteesColumn: GridColDef = {
    field: 'participants',
    headerName: 'meeting.participants',
    width: 500,
    editable: true,
    valueSetter: (value, row) => {
        if (value) {
            row.participants = value;
            return row;
        }
        return row;
    },
    display: 'flex'
    // valueFormatter: value => value?.toString()
};

export const statusColumn: GridColDef = {
    field: 'status', headerName: 'meeting.status',
    editable: false, display: 'flex'
}
export const libraryStatusColumn = { field: 'status', headerName: 'meeting.status', flex: 0.2, editable: false }
export const libraryIsPublishedColumn = { field: 'status', headerName: 'library.published.status', flex: 0.1, editable: false, display: 'flex' }
export const projectColumn: GridColDef<any> = { field: 'project', headerName: 'meeting.project', width: 180, editable: true, display: 'flex' }
export const defaultTagsColumn: GridColDef = {
    field: 'defaultTags',
    headerName: 'meeting.tags',
    width: 200,
    editable: true,
    valueSetter: (value, row) => {
        if (value) {
            row.defaultTags = value;
            return row;
        }
        return row;
    },
    display: 'flex'
};